import React, { useEffect, useCallback, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './Components/Menu';
import Home from './Pages/Home';
import Programas from './Pages/Programas';
//import ProgramaL from './Pages/landingPage/Programas'; // Importa el componente Programas
import Blogs from './Pages/Blogs';
import InicioSesion from './Pages/InicioSesion';
import Registro from './Components/Registro';
import Header from './Pages/landingPage/Header';
import Entrenadores from './Pages/landingPage/Entrenadores';
import AutomaticProgram from './Pages/landingPage/AutomaticProgram';
import { EntrenadorProvider } from './Pages/landingPage/EntrenadorContext';

const App = () => {
  const sections = useMemo(() => ['header', 'programas'], []);


  const scrollToSection = useCallback((index) => {
    const section = document.getElementById(sections[index]);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }, [sections]);

  const handleScroll = useCallback((event) => {
    const currentScroll = window.scrollY;
    const windowHeight = window.innerHeight;
    const currentIndex = Math.round(currentScroll / windowHeight);
    if (event.deltaY > 0) {
      if (currentIndex < sections.length - 1) {
        scrollToSection(currentIndex + 1);
      }
    } else {
      if (currentIndex > 0) {
        scrollToSection(currentIndex - 1);
      }
    }
  }, [scrollToSection, sections]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      const currentScroll = window.scrollY;
      const windowHeight = window.innerHeight;
      const currentIndex = Math.round(currentScroll / windowHeight);
      if (event.key === 'ArrowDown' && currentIndex < sections.length - 1) {
        scrollToSection(currentIndex + 1);
      } else if (event.key === 'ArrowUp' && currentIndex > 0) {
        scrollToSection(currentIndex - 1);
      }
    }
  }, [scrollToSection, sections]);

  useEffect(() => {
    window.addEventListener('wheel', handleScroll);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleScroll, handleKeyDown]);


  return (
    <Router>
      <EntrenadorProvider>
      <div>
         <Menu>
         <Routes>
          <Route exact path="/" component={Home} />
          <Route path="/Programas" component={Programas} />
          <Route path="/Blogs" component={Blogs} />
          <Route path="/InicioSesion" component={InicioSesion} />
          <Route path="./Components/Registro" component={Registro} />
          </Routes>
        </Menu>
        <Header />
        <Entrenadores />
        <AutomaticProgram />
     </div>
     </EntrenadorProvider>
    </Router>
  );
};

export default App;
