import React from 'react';
import '../css/Registro.css';

const Registro = () => {
  return (
    <div className="registro-container">
      <h2 className="registro-title">REGÍSTRATE</h2>
      <div className="registro-content">
        <div className="registro-form">
          <form>
            <div className="form-group">
              <label htmlFor="nombre">Nombre(s)</label>
              <input type="text" id="nombre" name="nombre" required />
            </div>
            <div className="form-group">
              <label htmlFor="apellidos">Apellidos</label>
              <input type="text" id="apellidos" name="apellidos" required />
            </div>
            <div className="form-group">
              <label htmlFor="correo">Correo Electrónico</label>
              <input type="email" id="correo" name="correo" required />
            </div>
            <div className="form-group">
              <label htmlFor="password">Contraseña</label>
              <input type="password" id="password" name="password" required />
            </div>
            <button type="submit" className="registro-button">Registro</button>
          </form>
        </div>
        <div className="registro-info">
          <p>Texto descriptivo sobre el registro y beneficios de registrarse...</p>
        </div>
      </div>
    </div>
  );
};

export default Registro;
