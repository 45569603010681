import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Menu.css'; 
import { ReactComponent as HomeIcon } from '../img/bar.svg'; 

const Menu = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) { // Ajusta este valor según cuándo quieras que el menú cambie
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`menu ${scrolled ? 'scrolled' : ''}`}>
        <div  className={`menu-section menu-left ${menuOpen ? 'open' : ''}`}>
          <Link to="/">
          <HomeIcon className="icon" /></Link>        
          <Link to="/Programas">Programas</Link>        
          <Link to="/Blogs">Blogs</Link>
        </div >
        <div className="menu-section menu-center">
          <Link to="/Blogs">Logo</Link>
        </div >
        <div className={`menu-section menu-right ${menuOpen ? 'open' : ''}`}>
          <Link to="/InicioSesion">Iniciar Sesion</Link>
          <Link to="./Registro">Registro</Link>
        </div >
        <div className="hamburger" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
    </nav>
  );
};

export default Menu;
