import React, { useState, useContext  } from 'react';
import '../../css/Entrenadores.css';
import { EntrenadorContext } from './EntrenadorContext';

// Datos de los entrenadores
const entrenadores = [
  {
    nombre: 'Caro Soto',
    ejercicio: 'Pilates',
    descripcion: 'Descripción sobre Caro Soto...',
    imagen: './Imagenes/Caro.jpg',
    instagram: 'https://www.instagram.com/juan',
    facebook: 'https://www.facebook.com/juan',
    twitter: 'https://twitter.com/juan',
    programas: [
      { nombre: 'FIBPILATES', descripcion: 'Esta clase se enfoca en movimientos conscientes que ayudan a tonificar y esculpir el cuerpo. ' +
                                          'Combina principios de pilates con ejercicios diseñados para mejorar la flexibilidad, fuerza y postura. ' +
                                          'Es ideal para quienes buscan desarrollar una musculatura larga y delgada, trabajando en armonía con el cuerpo.', video: './Video/clip.mp4' },
      { nombre: 'FIBSTRONG', descripcion: 'Es un entrenamiento de fuerza que puede realizarse utilizando implementos como pesas o el propio peso corporal. ' +
                                          'El objetivo principal es desarrollar masa muscular, aumentar la fuerza y mejorar la resistencia física. ' +
                                          'Esta clase es perfecta para quienes desean fortalecer su cuerpo de manera efectiva y progresiva.', video: './Video/clip.mp4' },
      { nombre: 'FIBHIIT', descripcion: 'Diseñada para aquellos que buscan un entrenamiento cardiovascular intenso y divertido.  El HIIT ' +
                                        '(High-Intensity Interval Training) combina ráfagas cortas de ejercicio de alta intensidad con períodos ' +
                                        'de descanso o actividad de baja intensidad. Es efectivo para quemar calorías, mejorar la resistencia ' +
                                        'Ecardiovascular y aumentar la capacidad aeróbica.', video: './Video/clip.mp4' },
      { nombre: 'FIBINTENSE', descripcion: 'Como su nombre lo indica, esta clase es una combinación intensa de los métodos Fibpilates, Fibstrong y FibHIIT. ' +
                                         'Ofrece un entrenamiento completo que trabaja todo el cuerpo a través de ejercicios funcionales de resistencia, ' +
                                         'cardio y fuerza. Es ideal para quienes buscan un desafío integral que mejore tanto la condición física  ' +
                                         'como la resistencia mental.', video: './Video/clip.mp4' },
      { nombre: 'FIBFLASH', descripcion: 'Son sesiones cortas y dinámicas diseñadas para ajustarse a estilos de vida ocupados. Incluye opciones como FIBFLOW, ' +
                                         'que incorpora movimientos de baile para mejorar la coordinación y la diversión durante el ejercicio, y FIBYOGA, ' +
                                         'que se centra en la meditación, relajación y la mejora de la flexibilidad a través de posturas de yoga. ' +
                                         'Estas clases son perfectas para mantenerse activo en poco tiempo y adaptarse a horarios apretados sin comprometer ' +
                                         'los beneficios del ejercicio físico regular.', video: './Video/clip.mp4' },
    ]
  },
  {
    nombre: 'Ana Gómez',
    ejercicio: 'Yoga',
    descripcion: 'Descripción sobre Ana Gómez...',
    imagen: './Imagenes/mora.jpg',
    instagram: 'https://www.instagram.com/ana',
    facebook: 'https://www.facebook.com/ana',
    twitter: 'https://twitter.com/ana',
    programas: [
      { nombre: 'Programa A', descripcion: 'Descripción del Programa A', video: './Video/clip2.mp4' },
      { nombre: 'Programa B', descripcion: 'Descripción del Programa B', video: './Video/clip3.mp4' }
    ]
  },
  // Añade más entrenadores aquí
];

const Entrenadores = () => {
  const { setEntrenadorSeleccionado, setProgramas } = useContext(EntrenadorContext);
  const [entrenadorActivo, setEntrenadorActivo] = useState(entrenadores[0]);

  const seleccionarEntrenador = (entrenador) => {
    setEntrenadorActivo(entrenador);
    setEntrenadorSeleccionado(entrenador);
    setProgramas(entrenador.programas);
  };

  return (
    <section className="entrenadores-section">
      <div className="entrenadores-content">
        <div className='entrenadores-tittle1'>
          <h2 className="entrenadores-title">Entrenadores</h2>
        </div>
        <div className="entrenadores-carousel">
          {entrenadores.map((entrenador, index) => (
            <img
              key={index}
              src={entrenador.imagen}
              alt={entrenador.nombre}
              className={`entrenador-img ${entrenadorActivo.nombre === entrenador.nombre ? 'selected' : ''}`}
              onClick={() => seleccionarEntrenador(entrenador)}
            />
          ))}
        </div>
        <div className="entrenador-info">
          <h3>{entrenadorActivo.nombre}</h3>
          <p><strong>{entrenadorActivo.ejercicio}</strong></p>
          <p>{entrenadorActivo.descripcion}</p>
          <div className="entrenador-social">
            <a href={entrenadorActivo.instagram} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href={entrenadorActivo.facebook} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href={entrenadorActivo.twitter} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Entrenadores;
