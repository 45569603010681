import React, { useContext, useEffect } from 'react';
import { EntrenadorContext } from './EntrenadorContext';
import '../../css/AutomaticProgram.css';

const Programas = () => {
  const { programas, programaActual, setProgramaActual } = useContext(EntrenadorContext);

  useEffect(() => {
    // Seleccionar el primer programa por defecto cuando se cargan los programas
    if (programas.length > 0 && !programaActual) {
      setProgramaActual(programas[0]);
    }
  }, [programas, programaActual, setProgramaActual]);

  const handleProgramaClick = (programa) => {
    setProgramaActual(programa);
  };

  return (
    <section className="programas-section">
      <div className='tittle-content'>  
        <div className='title'>
           <div className="programas-tittle">Programas</div>
        </div>
        <div className='tittle-links'>  
          <div className="programas-links">
              {programas.map((programa, index) => (
                <a 
                  key={index} 
                  href={`#${programa.nombre}`}
                  className={`programas-link ${programaActual === programa ? 'selected' : ''}`}
                  onClick={() => handleProgramaClick(programa)}
                >
                  {programa.nombre}
                </a>
              ))}
            </div>
        </div>
      </div>
      <div className="programas-content">
        {programaActual && (<div className="programas-video">
          <video controls className="programa-video">
             <source src={programaActual.video} type="video/mp4" />
             Your browser does not support the video tag.
           </video>
          </div>
        )}
        <div className="programas-text">
          {programaActual && (
            <div className="programa-description">
              <p>{programaActual ? programaActual.descripcion : ''}</p>
            </div>

          )}          
        </div>
      </div>
    </section>
  );
};

export default Programas;
