import React, { createContext, useState } from 'react';

export const EntrenadorContext = createContext();

export const EntrenadorProvider = ({ children }) => {
  const [entrenadorSeleccionado, setEntrenadorSeleccionado] = useState(null);
  const [programas, setProgramas] = useState([]);
  const [programaActual, setProgramaActual] = useState(null); // Agregar estado para el programa seleccionado


  return (
    <EntrenadorContext.Provider value={{ 
      entrenadorSeleccionado, 
      setEntrenadorSeleccionado, 
      programas, 
      setProgramas, 
      programaActual, 
      setProgramaActual 
    }}>
      {children}
    </EntrenadorContext.Provider>
  );
};
