import React from 'react';
import '../../css/Header.css'; // Importa el archivo CSS para el header
import videoPath from '../../Video/clip.mp4'; // Ruta del archivo de video


const Header = () => {
  return (
    <header className="header-video-container">
      <video className="header-video" autoPlay muted loop>
        <source src={videoPath} autoPlay muted loop type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button className="join-button">¡QUIERO UNIRME!</button>
      <div className="header-text">
        Fitness<br />
        Inspiración<br />
        Bienestar
      </div>
    </header>
  );
};

export default Header;
